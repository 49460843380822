
import React, { Component, useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Homepage from '../src/pages/Customlanding';
import Invstorpage from '../src/pages/Investor'
import BusinessOwner from './pages/BussinessOwner/BusinessOwner';
import "./App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AOS from "aos";
import "aos/dist/aos.css";
import CaseStudyPage from './pages/CaseStudyPage/index'
import FounderPage from './pages/FounderPage/FounderPage';
import { GlobalContext } from "./context/globalContext";
import Chatgpt from './pages/Chatgpt/Chatgpt';
import Alumni from './pages/Customlanding/Alumni'
import HomePage from './pages/Customlanding/Homepage'
export default function App() {
  const [selectedProfileData, setSelectedProfileData] = useState("");

  const value = {

    selectedProfileData,
    setSelectedProfileData,
  };
  useEffect(() => {

    AOS.init({
      disable: window.innerWidth < 768,

      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <GlobalContext.Provider value={value}>
      <div>
        {/* <Homepage /> */}

        <Router>

          <Switch>

            {/* <Redirect exact from="/" to="/founders" /> */}
            {/* <Redirect exact from="/:pathid" to="/founders/:pathid" /> */}
            <Route exact path="/" component={HomePage} />
            {/*  */}
          
            {/* <Redirect exact from="/:pathid" to="/founders/:pathid" /> */}
            {/* <Route  path="/Compare/:code" component={CompareHacker}/>
      <Route  path="/Exchange/Bibox" component={Bibox}/>
      <Route  path="/Exchange/Binance" component={Binance}/>
      <Route  path="/Exchange/Bittrex" component={Bittrex }/>
      <Route  path="/Exchange/Bitfinex" component={Bitfinex}/>
      <Route  path="/Exchange/Coss" component={Coss}/>
      <Route  path="/Exchange/Kraken" component={Kraken}/>
      <Route  path="/Exchange/Kucoin" component={Kucoin}/>
      <Route  path="/Exchange/Poloniex" component={Poloniex}/>
      <Route  path="/Exchange/Upbit" component={Upbit}/>
      <Route  path="/Compare" component={Compare}/> */}
          </Switch>

        </Router>

      </div>
    </GlobalContext.Provider>
  )
}
